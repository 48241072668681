














































import {
  Component,
  Prop,
  Watch,
  Vue
} from 'vue-property-decorator';

@Component({
  name: 'top_section2'
})
export default class extends Vue {
  @Prop({default: {random: 0, command: ''}}) updateCommand!: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  };

  swiperOptions:{[key in string]:any} = {
    autoplay: {
      delay: 10000,
      disableOnInteraction: false,
    },
    loop: true,
    pagination: {
      el: '.slider-num',
      type: 'fraction',
    },
  }

  bunkList:any[] = []

  get bunkData() {
    return this.$store.state.bigScreen.bunkData;
  }

  @Watch('bunkData')
  changeBunkData() {
    this.buildBunkList();

  }

  @Watch('updateCommand')
  changeUpdateCommand(val: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  }) {
    if (val.command === 'bunk') {
      this.updateBunkData();
    }
  }

  mounted() {
    this.updateBunkData();
  }

  updateBunkData() {
    this.$store.dispatch('bigScreen/getBunkData', {vm: this})
  }

  buildBunkList(){
    let len=this.bunkData.length;
    let pageNum=16,i=0;
    let tmpArr:any[]=[];
    let tmpBunkListArr:any[]=[]

    do {
      let startIndex=pageNum*i;
      let endIndex=startIndex+pageNum
      tmpArr= this.bunkData.slice(startIndex,endIndex);
      tmpBunkListArr.push(tmpArr);
      i++;
      if(endIndex > len) break;
    }while (tmpArr.length === pageNum)

    this.bunkList = tmpBunkListArr;
  }

}
