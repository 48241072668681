

















































import {
  Component,
  Prop,
  Watch,
  Vue
} from 'vue-property-decorator';

@Component({
  name: 'top_section1'
})
export default class extends Vue {
  @Prop({default: {random: 0, command: ''}}) updateCommand!: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  };

  today:{[key in string]:any} = {
    "bed_total": 0,
    "check_in_total": 0,
    "online_bed_total": 0,
    "in_bed_total": 0,
    "today_measure_count": 0,
    "today_visit_count": 0,
    "today_place_order_count": 0
  }


  get todayData() {
    return this.$store.state.bigScreen.todayData;
  }

  @Watch('todayData')
  changeTodayData() {
    this.today = this.todayData;
  }

  @Watch('updateCommand')
  changeUpdateCommand(val: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  }) {
    // console.log(val);
    if (val.command === 'today') {
      this.updateTodayData();
    }
  }

  mounted() {
    this.updateTodayData();
  }

  updateTodayData() {
    this.$store.dispatch('bigScreen/getTodayData', {vm: this})
  }

}
