

























import {
  Component,
  Prop,
  Watch,
  Vue
} from 'vue-property-decorator';
import {defaultTheme} from '@/util/echart_themes'

@Component({
  name: 'bottom_section1'
})
export default class extends Vue {
  @Prop({default: {random: 0, command: ''}}) updateCommand!: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  };

  genderChartData = {};
  ageChartData = {};
  visitsUser7DChartData = {};
  order7DChartData = {};

  lineChartTheme = {
    categoryAxis: {
      axisLabel: {
        fontSize: '20px',
        color: '#fff'
      },
      axisTick: {
        show: false,
      },
    },
    valueAxis: {
      nameTextStyle: {
        fontSize: '16px',
        color: '#ffffff'
      },
      axisLabel: {
        fontSize: '16px',
        color: '#fff'

      },
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        interval: 1,
        lineStyle: {
          color: ['#fff'],
          type: [3, 5], // 虚线样式[线，间隔]
          opacity: '1'
        },
      }
    },
  }

  ageChartTheme = {
    color: {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 1,
      y2: 1,
      colorStops: [{
        offset: 0, color: '#7E99FB' // 0% 处的颜色
      }, {
        offset: 1, color: '#355EF6' // 100% 处的颜色
      }],
      global: false // 缺省为 false
    },
    categoryAxis: {
      axisLabel: {
        fontSize: '20px',
        color: '#fff'
      },
      axisTick: {
        show: false,
      },
    },
    valueAxis: {
      nameTextStyle: {
        fontSize: '16px',
        color: '#ffffff'
      },
      axisLabel: {
        fontSize: '16px',
        color: '#fff'

      },
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        interval: 1,
        lineStyle: {
          color: ['transparent', '#fff'],
          type: [3, 5], // 虚线样式[线，间隔]
          opacity: '1'
        },
      }
    },
  }
  defaultTheme = defaultTheme;

  // visitsUser7DData: {},
  // ageData: {},
  // genderData: {}

  get visitsUser7DData() {
    return this.$store.state.bigScreen.visitsUser7DData;
  }

  get ageData() {
    return this.$store.state.bigScreen.ageData;
  }

  get genderData() {
    return this.$store.state.bigScreen.genderData;
  }

  get orderData() {
    return this.$store.state.bigScreen.orderData;
  }


  @Watch('visitsUser7DData')
  changeVisitsUser7DData() {
    this.setVisitsUser7DChart();
  }

  @Watch('ageData')
  changeAgeData() {
    this.setAgeChart();
  }

  @Watch('genderData')
  changeGenderData() {
    this.setGenderChart();
  }

  @Watch('orderData')
  changeOrderData() {
    this.setOrder7DChart();
  }

  @Watch('updateCommand')
  changeUpdateCommand(val: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  }) {
    // console.log(val);
    if (val.command === 'user') {
      this.updateUserData();
    }
  }

  mounted() {
    this.updateUserData();
  }

  updateUserData() {
    this.$store.dispatch('bigScreen/getUserData', {vm: this})
  }

  setGenderChart() {
    let genderData = this.genderData;

    this.genderChartData = {
      "color": [
        "#5EB0DF",
        "#D3518F",
        "#393939",
      ],
      // title: {
      //   text: `总用户量`,
      //   subtext: genderData.total,
      //   textStyle: {
      //     fontSize: '20px',
      //     fontWeight: 'normal'
      //   },
      //   subtextStyle: {
      //     fontSize: '24px',
      //     color: '#fff',
      //   },
      //   left: '50%',
      //   top: '36%',
      //   textAlign: 'center',
      //   fontWeight: 'normal'
      // },
      series: [
        {
          name: '用户性别',
          type: 'pie',
          radius: ['60%', '85%'],
          startAngle: '70',
          label: {
            fontSize: '20px',
            color: '#fff',
            textBorderColor: 'transparent',
            formatter(params: any) {
              return `${params.name}:${params.percent}%`
            },
          },
          data: genderData.data
        },
      ]

    };
  }

  setVisitsUser7DChart() {
    let visitsUser7DData = this.visitsUser7DData;

    this.visitsUser7DChartData = {
      color: ['#F97B41'],
      grid: {
        top: '15%',
        left: '1%',
        right: '2%',
        bottom: '0',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: visitsUser7DData.xAxis,
        axisLabel: {
          fontSize: '20px'
        },
      },
      yAxis: {
        type: 'value',
        name: '(人数)',
        nameTextStyle: {
          color: '#ffffff'
        },
        axisLabel: {
          fontSize: '18px'
        },
      },
      series: [
        {
          data: visitsUser7DData.data,
          type: 'line'
        }
      ]
    };
  }

  setOrder7DChart() {
    let order7DData = this.orderData.place_order_statistics;

    this.order7DChartData = {
      color: ['#F97B41'],
      grid: {
        top: '15%',
        left: '1%',
        right: '2%',
        bottom: '0',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: order7DData.xAxis,
        axisLabel: {
          fontSize: '20px'
        },
      },
      yAxis: {
        type: 'value',
        name: '(订单数)',
        nameTextStyle: {
          color: '#ffffff'
        },
        axisLabel: {
          fontSize: '18px'
        },
      },
      series: [
        {
          data: order7DData.data,
          type: 'line'
        }
      ]
    };
  }

  setAgeChart() {
    let ageData = this.ageData;

    this.ageChartData = {
      "color": [
        "#0250d8",
        "#eaeaea",
      ],
      grid: {
        top: '15%',
        left: '1%',
        right: '8%',
        bottom: '0',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ageData.xAxis,
          name: '(岁)',
          nameTextStyle: {
            color: '#ffffff'
          },
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            fontSize: '20px'
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '(人数)',
          nameTextStyle: {
            color: '#ffffff'
          },
          axisLabel: {
            fontSize: '20px'
          },
        }
      ],
      series: [
        {
          name: '人数',
          type: 'bar',
          barWidth: '50%',
          data: ageData.data,
        }
      ]
    };
  }
}
