










































import {
  Component,
  Prop,
  Watch,
  Vue
} from 'vue-property-decorator';

@Component({
  name: 'top_section4'
})
export default class extends Vue {
  @Prop({default: {random: 0, command: ''}}) updateCommand!: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  };

  /* circle color array*/
  colors = [
    '238, 179, 26',
    '92, 171, 239',
  ]

  circleData:any[]=[];

  get orderData() {
    return this.$store.state.bigScreen.orderData;
  }

  @Watch('orderData')
  changeOrderData() {
    this.buildCircleData();
  }

  @Watch('updateCommand')
  changeUpdateCommand(val: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  }) {
    if (val.command === 'order') {
      this.updateOrderData();
    }
  }

  mounted() {
    this.updateOrderData();
  }

  updateOrderData() {
    this.$store.dispatch('bigScreen/getOrderData', {vm: this})
  }

  buildCircleData() {
    this.circleData = [
      {
        name:this.orderData.conversion_rate.name,
        percent:this.orderData.conversion_rate.percent,
      },{
        name:this.orderData.payment_rate.name,
        percent:this.orderData.payment_rate.percent,
      },
    ]
  }

}
