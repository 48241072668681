










































import {
  Component,
  Vue
} from 'vue-property-decorator';
import TopSection1 from '@/views/big_screen/top-section1.vue';
import TopSection2 from '@/views/big_screen/top-section2.vue';
import TopSection3 from '@/views/big_screen/top-section3.vue';
import TopSection4 from '@/views/big_screen/top-section4.vue';
import BottomSection1 from '@/views/big_screen/bottom-section.vue';
import {throttle} from '@/util/helper';

@Component({
  name: 'big_screen',
  components: {
    TopSection1,
    TopSection2,
    TopSection3,
    TopSection4,
    BottomSection1,
  }
})
export default class extends Vue {

  // baseUrl="http://yiyang.zhikangle.com"
  bodyPngIsLoaded = false;
  bodyPngUrl = `${this.$cfg.baseUrl}/images/body/body_50.png`;
  bodyMp4Url = `${this.$cfg.baseUrl}/images/body/body.mp4`;
  curTime = '';

  defaultWidth = 2638;
  defaultHeight = 1439;
  boxStyle = {
    left: '0',
    top: '0',
    width: `${this.defaultWidth}px`,
    height: `${this.defaultHeight}px`,
    transform: `scale(1)`,
  }

  bigImageIsPng = false;

  updateCommand: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  } = {
    random: Math.random(),
    command: ''
  }

  created() {
    if(this.bigImageIsPng){
      this.loadBodyPng();
    }
  }

  mounted() {
    window.addEventListener('resize', throttle(this.setScreenScale, 60))
    this.setScreenScale();

    this.$nextTick(() => {
      this.updateTime();
      this.updateData();
    })

  }

  updateData() {
    this.updateTodayData();
    this.updateBunkData();
    this.updateMeasureData();
    this.updateUserData();
    this.updateOrderData();
  }

  updateTodayData() {
    // The next time for update,range:5s-10s.
    let nextTime = Math.floor(Math.random() * 5000) + 5000
    setTimeout(() => {
      this.updateCommand = {
        random: Math.random(),
        command: 'today'
      }
      this.updateTodayData();
    }, nextTime)
  }

  updateBunkData() {
    // The next time for update,range:5s-10s.
    let nextTime = Math.floor(Math.random() * 5000) + 5000
    setTimeout(() => {
      // console.log('update t2.'+ nextTime)
      this.updateCommand = {
        random: Math.random(),
        command: 'bunk'
      }
      this.updateBunkData();
    }, nextTime)
  }

  updateMeasureData() {
    // The next time for update,range:5s-10s.
    let nextTime = Math.floor(Math.random() * 5000) + 5000
    setTimeout(() => {
      // console.log('update t3.'+ nextTime)
      this.updateCommand = {
        random: Math.random(),
        command: 'measure'
      }
      this.updateMeasureData();
    }, nextTime)
  }

  updateUserData() {
    // The next time for update,range:5s-10s.
    let nextTime = Math.floor(Math.random() * 5000) + 5000
    setTimeout(() => {
      // console.log('update user.'+ nextTime)
      this.updateCommand = {
        random: Math.random(),
        command: 'user'
      }
      this.updateUserData();
    }, nextTime)
  }

  updateOrderData() {
    // The next time for update,range:5s-10s.
    let nextTime = Math.floor(Math.random() * 5000) + 5000
    setTimeout(() => {
      // console.log('update user.'+ nextTime)
      this.updateCommand = {
        random: Math.random(),
        command: 'order'
      }
      this.updateOrderData();
    }, nextTime)
  }

  updateTime() {
    // console.log(this.$day(new Date()).format('YYYY年MM月DD日 HH:mm:ss'));
    setInterval(() => {
      this.curTime = this.$day(new Date()).format('YYYY年MM月DD日 HH:mm:ss')
    }, 1000)
  }

  destroy() {
    window.removeEventListener('resize', throttle(this.setScreenScale, 60))
  }

  setScreenScale() {
    let winWidth = document.documentElement.offsetWidth || document.body.offsetWidth;
    let winHeight = document.documentElement.offsetHeight || document.body.offsetHeight;
    let winRatio = (winWidth / winHeight).toFixed(5);
    let defaultRatio = (this.defaultWidth / this.defaultHeight).toFixed(5);
    let boxLeft, boxTop, boxScale;

    // console.log(winWidth,winHeight);
    // console.log(winRatio,defaultRatio, (winRatio/defaultRatio));
    if (winRatio < defaultRatio) {
      boxScale = (winWidth / this.defaultWidth).toFixed(5);
    } else {
      boxScale = (winHeight / this.defaultHeight).toFixed(5);
    }

    boxLeft = ((winWidth - this.defaultWidth) / 2).toFixed(5)
    boxTop = ((winHeight - this.defaultHeight) / 2).toFixed(5);
    Object.assign(this.boxStyle, {
      transform: `scale(${boxScale})`,
      left: `${boxLeft}px`,
      top: `${boxTop}px`
    })
  }

  loadBodyPng() {

    let img = new Image();
    img.src = this.bodyPngUrl;
    console.log(this.bodyPngUrl);
    img.onload = () => {
      if (img.width > 0) {
        this.bodyPngIsLoaded = true;
      }
    }
  }

}
