































import {
  Component,
  Prop,
  Watch,
  Vue
} from 'vue-property-decorator';
import {defaultTheme} from '@/util/echart_themes'

@Component({
  name: 'top_section3'
})
export default class extends Vue {
  @Prop({default: {random: 0, command: ''}}) updateCommand!: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  };

  recordList: any[] = []

  waringChartData = {};


  waringChartTheme = {
    color: [
      {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 1,
        y2: 1,
        colorStops: [{
          offset: 0, color: '#7E99FB' // 0% 处的颜色
        }, {
          offset: 1, color: '#355EF6' // 100% 处的颜色
        }],
        global: false // 缺省为 false
      },
      '#ea4141'
    ],
    categoryAxis: {
      axisLabel: {
        fontSize: '20px',
        color: '#fff'
      },
      axisTick: {
        show: false,
      },
    },
    valueAxis: {
      nameTextStyle: {
        fontSize: '16px',
        color: '#ffffff'
      },
      axisLabel: {
        fontSize: '16px',
        color: '#fff'

      },
      axisLine: {
        show: true,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        interval: 1,
        lineStyle: {
          color: ['transparent', '#fff'],
          type: [3, 5], // 虚线样式[线，间隔]
          opacity: '1'
        },
      }
    },
  }
  defaultTheme = defaultTheme

  get measureData() {
    return this.$store.state.bigScreen.measureData;
  }

  @Watch('measureData')
  changeMeasureData() {
    this.recordList = this.measureData.records;
    this.setWaringChart();
  }

  @Watch('updateCommand')
  changeUpdateCommand(val: {
    random: number;
    command: 'today' | 'bunk' | 'measure' | 'order' | 'user' | '';
  }) {
    if (val.command === 'measure') {
      this.updateMeasureData();
    }
  }

  mounted() {
    this.updateMeasureData();
  }

  updateMeasureData() {
    this.$store.dispatch('bigScreen/getMeasureData', {vm: this})
  }


  setWaringChart() {
    let waringData = this.measureData.waring;

    this.waringChartData = {
      grid: {
        top: '18%',
        left: '22%',
        right: '2%',
        bottom: '0',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: waringData.xAxis,
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            fontSize: '20px'
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: '(次)',
          nameTextStyle: {
            color: '#ffffff'
          },
          axisLabel: {
            fontSize: '20px'
          },
        }
      ],
      series: [
        {
          name: '测量正常',
          type: 'bar',
          stack: 'measure',
          label: {
            show: true,
            fontSize: 18,
            position: 'insideBottom',
            formatter(item: any) {
              return Number(item.value) > 0 ? item.value : '';
            }
          },
          data: waringData.normal,
        }, {
          name: '测量告警',
          type: 'bar',
          stack: 'measure',
          label: {
            show: true,
            fontSize: 18,
            position: 'insideTop',
            formatter(item: any) {
              // console.log(name);
              return Number(item.value) > 0 ? item.value : '';
            }
          },
          data: waringData.waring,
        },
      ]
    };
  }


}
